import React from "react";
import "../styles.css/Header.css";

function Header() {
  return (
    <header className="header-div">
      <h3 className="header">הפרלמנט</h3>
    </header>
  );
}

export default Header;
