export const emotionSelectionImages = [
  {
    id: "AYALON_PROTEST",
    description: "Ayalon Protest in Saturday night",
  },
  {
    id: "THE_SLAVE_MARCH",
    description: "The slave march in protest of the legal revolution",
  },
  {
    id: "HAREDI_LEARNING",
    description: "A haredi man learning tora",
  },
];